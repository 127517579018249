import React from "react";



import { Link } from "react-router-dom";

const HomePage = () => {
  return (
    <>
    <div className="main_page_container">

    <header id="head">
                <div className="banner-content">
                    <h1>Real-Time Hydro-Meteorological <br/>
                    Monitoring and Forecast
                    </h1>
                   
                </div>
             {/* <Link to="/map">   <button className="homebtn"><span>Map Viewer </span></button></Link> */}

                
            </header>
    </div>

  </>
  )
}

export default HomePage