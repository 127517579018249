import React, { createContext, useContext, useState } from 'react';
import PlaceAttributes from "../assets/PlaceAttributes.json"
const SelectedFeatureContext = createContext();

export const useSelectedFeatureContext = () => useContext(SelectedFeatureContext);


export const SelectedFeatureProvider = ({ children }) => {
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [selectedTehsil, setSelectedTehsil] = useState(null);
  const [tehsilList, setTehsilList] = useState([]);


  const handleDistrictSelect = (e) => {
    let items = PlaceAttributes.filter((item) => item.DISTRICT === e.target.value);

    items = [...new Set(items.map((item) => item))];
    items.sort();
    setTehsilList(items);
    setSelectedDistrict(e.target.value)
    setSelectedTehsil(null)


  };



  const handleTehsilSelect = (e) => {
    setSelectedTehsil(e.target.value)
  };
  


  return (
    <SelectedFeatureContext.Provider value={{ selectedDistrict,handleTehsilSelect,handleDistrictSelect ,selectedTehsil,tehsilList}}>
      {children}
    </SelectedFeatureContext.Provider>
  );
};
