import React, { Suspense, lazy } from 'react'
import { HashRouter as Router, Routes, Route } from 'react-router-dom'


import NavbarMain from './components/NavbarMain'

import ScrollToTop from './components/ScrollToTop'
import Preloader from './components/Preloader'

import AlertMessage from './components/AlertMessage'
import HomePage from './pages/HomePage'



const Dashboard = lazy(() => import('./pages/Dashboard'));
const WeatherPage = lazy(() => import('./pages/WeatherPage'));
const ContactUsPage = lazy(() => import('./pages/ContactUsPage'));
const InfoPage = lazy(() => import('./pages/InfoPage'));
const HydrologicalPage = lazy(() => import('./pages/HydrologicalPage'));
const MeteorologicalPage = lazy(() => import('./pages/MeteorologicalPage'));
const DroughtPage = lazy(() => import('./pages/DroughtPage'));



const App = () => {
  return (
    <Router>

       <NavbarMain/>
       <AlertMessage />
       <Suspense fallback={<Preloader />}>

      <Routes>
        <Route path='/' element={<HomePage/>}/>
        <Route path='/map' element={<Dashboard/>}/>
        <Route path='/meteorological' element={<MeteorologicalPage/>}/>
        <Route path='/hydrological' element={<HydrologicalPage/>}/>
        <Route path='/drought' element={<DroughtPage/>}/>
        <Route path='/weather' element={<WeatherPage/>}/>
        <Route path='/info' element={<InfoPage/>}/>

        <Route path='/contact' element={<ContactUsPage/>}/>
        
      </Routes>

      </Suspense>
        <ScrollToTop/>

    </Router>
    
  )
}

export default App