import React, { useEffect, useState } from 'react';
import Logo from "../assets/images/UNICEF_Logo.png"
import { FaBars, FaTimes } from 'react-icons/fa';
import { FaExternalLinkAlt } from "react-icons/fa";
import { Link, NavLink } from 'react-router-dom';

const NavbarMain = () => {

    const [showMenu, setShowMenu] = useState(false);

    const handleToggle = () => {
        setShowMenu(!showMenu);
    };

    const handleLinkClick = () => {
        setShowMenu(false);
    };



    return (
        <>
            <div className='navbar_main_container'>
                <div className='short_nav_container'>
                    <div className='logo_text'>
                        <h1>ClimateAdapt Dashboard</h1>
                    </div>
                    <div className="navbar__logo">
                            <img src={Logo} alt="nav_logo" />
                    </div>

                    <div className="navbar__toggle scrolled" onClick={handleToggle}>
                        {showMenu ? <FaTimes /> : <FaBars />}
                    </div>


                </div>


                <div className='navbar_container'>

                    



                    <div className={`main_nav ${showMenu ? 'show' : ''}`}>
                        <div className="nav__content">
                            

                            <div className='nav__list'>
                                <NavLink 
                                className={({ isActive }) => (isActive ? 'active_nav' : 'nav__item')}
        
                                 to="/" onClick={handleLinkClick}>
                                    Home
                                </NavLink>

                                


                                <NavLink 
                                 className={({ isActive }) => (isActive ? 'active_nav' : 'nav__item')}
                                  to="/meteorological" onClick={handleLinkClick}>
                                   Meteorological Conditions
                                </NavLink>

                                <NavLink className={({ isActive }) => (isActive ? 'active_nav' : 'nav__item')}
                                  to="/hydrological" onClick={handleLinkClick}>
                                   Hydrological Conditions
                                </NavLink>

                                <NavLink className={({ isActive }) => (isActive ? 'active_nav' : 'nav__item')}
                                  to="/drought" onClick={handleLinkClick}>
                                  Drought Conditions
                                </NavLink>
                                <NavLink 
                                 className={({ isActive }) => (isActive ? 'active_nav' : 'nav__item')}
                                  to="/weather" onClick={handleLinkClick}>
                                   Weather Conditions
                                </NavLink>

                               
                                <NavLink 
                                 className={({ isActive }) => (isActive ? 'active_nav' : 'nav__item')}
                                  to="/map" onClick={handleLinkClick}>
                                   Map
                                </NavLink>


                                <a className="nav__item"
                                  href="https://akankshayadaw.users.earthengine.app/view/test" onClick={handleLinkClick} target='_blank' rel="noreferrer noopener">
                                  Vegetation condition&nbsp;<FaExternalLinkAlt />
                                </a>

                                <a className="nav__item"
                                  href="https://akankshayadaw.users.earthengine.app/view/lulc" onClick={handleLinkClick} target='_blank' rel="noreferrer noopener">
                                   Land Use&nbsp;<FaExternalLinkAlt />
                                </a>

                                <NavLink 
                                className={({ isActive }) => (isActive ? 'active_nav' : 'nav__item')}
                                 to="/info" onClick={handleLinkClick}>
                                    Info
                                </NavLink>
                                <NavLink className={({ isActive }) => (isActive ? 'active_nav' : 'nav__item')}
                                  to="/contact" onClick={handleLinkClick}>
                                    Contact Us
                                </NavLink>
                                <div className="main_nav_logo">
                    
                    <img src={Logo} alt="nav_logo" />
        
            </div>
                                
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>



    )
}

export default NavbarMain
